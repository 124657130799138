import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import NotFoundImage from "../svg/error.svg"
import Layout from "../components/Layout/Layout"
import { useUIState } from "../contexts/UIStateContext"


const View = styled(Container)`
padding: 50px 0 1.5rem;
min-height: calc(100vh - ${props => props.offset}px - 1.5rem);     

svg {       
  width: 450px;
}

@media (max-width: 768px) {
    min-height: calc(100vh - ${props => props.mobileoffset}px - 1.5rem);
  }
`
const CustomLink = styled(Link)`
  color: black;

    :hover{
      color: black;
      text-decoration: underline;
    }
`

const Textbox = styled(Col)`

    @media (min-width: 768px) {
        padding-top: 80px;
    }

    @media (min-width: 992px) {
        padding-top: 100px;
    }

    @media (min-width: 1200px) {
        padding-top: 120px;
    }
`

const NotFoundPage = ({ location }) => {
  const { totalOffset, mobileoffset } = useUIState()

  return (
    <Layout location={location} backgroundColor="bgCreme">
      <View fluid className="px-0" offset={totalOffset} mobileoffset={mobileoffset}>
        <Row className="mx-auto">
          <Col md={{ span: 6, order: 2 }} lg={{ span: 4 }} xl={{ span: 4 }} className="d-flex justify-content-center justify-content-md-end align-items-md-end mb-5 mb-md-0">
            <NotFoundImage/>
          </Col>
          <Textbox md={{ span: 6, order: 1 }} lg={{ span: 4, offset: 3 }} xl={{ span: 4, offset: 2 }}>
            <title>404</title>
            <h1>Error 404</h1>
            <p>Leider konnten wir unter dieser URL nichts finden.</p>
            <p><CustomLink to={'/'}>Hier</CustomLink> finden Sie unsere Projekte.</p>
          </Textbox>
        </Row>
      </View>
    </Layout>
  )
}

export default NotFoundPage
